// 图片上传接口
import { apiClient, baseURL } from "@/services/HttpService";
import OSS from "ali-oss";
import Moment from "moment";
import JSEncrypt from "jsencrypt";

const imgUrl = `${baseURL}/upload/product_picture`;
//凭证上传
const imgVoucherUrl = `${baseURL}/upload/offline_payment_file`;

let expirationTime = "";
let client = null;

async function refreshToken() {
  // 生成加密时间戳
  const encryptor = new JSEncrypt();
  const pubKey =
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDDxuCrJS+yGFOJy450IrU52uWrMYB5BvpuEvVYHM6ryKWWS+lnLTqzFNNFhJYU7wMcMQgMAjYeEvJXWhCYC2F2OXVv1xykH8GuKUixAJz+GB44Fe3hIi8Gzl2zqEpt5mTPNqup2TA/OhMZBpaqEUO1pGzxdqReBwCF/OvBgjEOgQIDAQAB";
  encryptor.setPublicKey(pubKey);
  const rsaPassWord = encryptor.encrypt(String(new Date().getTime()));
  const param = {
    rsaPassWord: rsaPassWord
  };
  // 请求stsToken
  const { data = {} } =
    (await apiClient.post("/getUploadStsSecurityToken", param)) || {};
  const { accessKeyId, accessKeySecret, securityToken, expiration } =
    data.data || {};
  // 更新OSS配置
  client = new OSS({
    region: "oss-cn-hangzhou",
    accessKeyId,
    accessKeySecret,
    stsToken: securityToken,
    bucket: "cedte-image"
  });
  // 更新过期时间
  expirationTime = expiration;
}

async function uploadToOSS(type, file) {
  if (!expirationTime || new Date() - 10000 >= new Date(expirationTime)) {
    await refreshToken();
  }
  const fileSuffix = file.name.slice(file.name.lastIndexOf("."));
  return client.put(
    `${type}/${Moment().format("YYYYMMDDHHmmssSSS")}_${type}${fileSuffix}`,
    file
  );
}

export async function upload(type, file, fileFormat) {
  if (process.env.NODE_ENV === "production") {
    try {
      const result = await uploadToOSS(type, file);
      return {
        data: {
          code: "SUCCESS",
          data: result.name
        }
      };
    } catch (e) {
      console.log(e);
      return {
        data: {
          code: "FAILED",
          errorMsg: e.message
        }
      };
    }
  } else {
    const formData = new FormData();
    formData.append("file", file, file.name);
    let url = "";
    if (fileFormat === "pdf") {
      url = `${baseURL}/upload-word/${type}`;
    } else {
      url = `${baseURL}/upload/${type}`;
    }
    return apiClient.post(url, formData);
  }
}

export { imgUrl, imgVoucherUrl };
